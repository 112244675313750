import Img1 from '../../img/img1.png';
import {CronogramaBackGroud, CronogramaDiv,CronogramaHeader,HeaderDiv } from './CronogramaStyled'
import {BoxCronograma } from './boxCronograma/BoxCronograma';





export function Cronograma(){

    return(
        <CronogramaDiv>
            <HeaderDiv>
            <CronogramaHeader>
                Cronograma
            </CronogramaHeader>
            </HeaderDiv>
            <BoxCronograma
            TituloCronograma='Período de Inscrições'
            DataCronograma="10/03 até 28/03"   
            />
            <BoxCronograma
            TituloCronograma='Dinâmicas'
            DataCronograma="03/03"   
            />
            <BoxCronograma
            TituloCronograma='Entrevistas'
            DataCronograma="14/04 até 25/04"   
            />
            {/* <BoxCronograma
            TituloCronograma='Resultado Inicial'
            DataCronograma="22/04"   
            /> */}
            <BoxCronograma
            TituloCronograma='Período Trainee'
            DataCronograma="05/05 até 20/06"   
            />
            {/* <BoxCronograma
            TituloCronograma='Resultado Final'
            DataCronograma="28/05"   
            /> */}
        </CronogramaDiv>
    );
}