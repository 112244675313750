import React from "react"
import Logo from "../../img/logo1.png"
import Insta from "../../img/insta.png"
import Face from "../../img/face.png"
import Linkedin from "../../img/linkedin.png"
import Phone from "../../img/phone.png"
import { Body,BodyLogo,ImgLogo,Sobre,BodyAtlas,BodyDireita,BodyHeader,BodyInformaçoes,BodyLink,BodyRedesSociais,BodyTelefone,Link,Link2,LogoFace,LogoInsta,LogoLinkedin,MenuLink,Telefone,PhoneIcon,Email,Endereço,Atlas } from "./RodapeStyled"

const Rodape = () => {
    return(
        <div>
            <Body>
                <BodyLogo>
                    <ImgLogo src = {Logo}/>
                    <Sobre>Somos uma empresa júnior que preza pelo trabalho eficiente, de qualidade e que atinja todas as suas expectativas.</Sobre>
                </BodyLogo>
                <BodyHeader>
                    <BodyLink>
                        <ul>
                            <li>
                                <Link href="/">
                                    Início
                                </Link>
                            </li>
                        </ul>
                        <ul>
                            <li><Link href="/servicos">
                                    Serviços
                                </Link>
                            </li>
                            <li>
                                <Link>Elétrica</Link>
                            </li>
                            <li>
                                <Link>Eletrônica</Link>
                            </li>
                            <li>
                                <Link>TI</Link>
                            </li>
                        </ul>

                        <ul>
                            <li>
                                <Link2 href="/">
                                    Início
                                </Link2>
                            </li>
                            <li>
                            <Link2 href="/servicos">
                                    Serviços
                                </Link2>
                            </li>
                            <li>
                            <Link2 href="/nos">
                                    Nós
                                </Link2>
                            </li>
                            <li>
                            <Link2 href="/ps">
                                    Seja um Titã
                                </Link2>
                            </li>
                        </ul>


                        <ul >
                            <li> <Link href="/nos">
                                    Nós
                                </Link>
                            </li>
                            <li>
                                <Link>A Atlas</Link>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <Link href="/ps">
                                    Seja um Titã
                                </Link>
                            </li>
                        </ul>
                        
                    </BodyLink>  
                </BodyHeader>
                <BodyDireita>
                    <BodyRedesSociais>
                        <MenuLink href="https://www.instagram.com/atlastecnologia/"  target="_blank">
                            <LogoInsta src={Insta}/>
                        </MenuLink>
                        <MenuLink href="https://www.facebook.com/atlastecnologia" target="_blank">
                            <LogoFace src={Face}/>
                        </MenuLink>  
                        <MenuLink href="https://www.linkedin.com/company/atlas-jr/" target="_blank">
                            <LogoLinkedin src={Linkedin}/>
                        </MenuLink>
                    </BodyRedesSociais>
                    <BodyTelefone>
                        <PhoneIcon src={Phone}/>
                        <Telefone>(31) 97107-6950</Telefone>
                    </BodyTelefone>
                    <BodyInformaçoes>
                        <Email>contato@atlasjr.com</Email>
                        <Endereço>Rua Irmã Ivone Drumond, 200 - Distrito Industrial II, Itabira - MG</Endereço>
                        <Atlas>Atlas Soluções em Tecnologia, 2025</Atlas>
                    </BodyInformaçoes>
                    <BodyAtlas>
                        
                    </BodyAtlas>
                </BodyDireita>
            </Body>
        </div>
    )
}


export default Rodape;