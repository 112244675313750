import Countdown from 'react-countdown';
import {CountDownDiv,HeaderTitle,TitleHeaderDiv,DivBody,
  ButtonsDiv,CountDownText,CountDownTitle,CountDownBody,TextYellow, Btn,LinkC} from './CountdownStyled';
import Count from './Count';



export function CountDown(){
  return(
    <DivBody>
    <TitleHeaderDiv>
      <HeaderTitle>
        <TextYellow>
        Inscreva-se&nbsp;
        </TextYellow>
      no processo seletivo
      </HeaderTitle>
    </TitleHeaderDiv>
      <CountDownBody>
    <CountDownDiv>
    <TitleHeaderDiv>
      <CountDownTitle>
      Dinâmicas
      </CountDownTitle>
    </TitleHeaderDiv>
    <TitleHeaderDiv>
      <CountDownText>
      Restam
      </CountDownText>
    </TitleHeaderDiv>
     <Count date='2025-04-03T23:59:59'/> 
    </CountDownDiv>
      </CountDownBody>
    <ButtonsDiv>
    <Btn><LinkC href="#" target="_blank">Inscreva-se aqui</LinkC></Btn> 
    <Btn><LinkC href="#" target="_blank">Edital 2025.1</LinkC></Btn> 
    </ButtonsDiv>
    </DivBody>
  );
}